import BaseSelect from '@/components/common/base-select/base-select.vue'
import supplierSelect from '@/components/packages/supplier-select/supplier-select'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessCode',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      attrs: {
        placeholder: '请选择',
        filterable: true,
        selectedField: ['keyId', 'enterpriseName'],
        maxlength: 50,
        ref: 'searchSelect'
      },
      tag: supplierSelect
    },
    {
      label: '签约状态',
      prop: 'status',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.signStatusOptions,
        selectedField: ['dictId', 'dictName'] // 字典未出 暂用死数据
      }
    }
  ]
}
// 盖章文件form
export const dataTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessCode'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0')
      }
    },
    {
      label: '盖章文件数',
      prop: 'contractCount',
      'show-overflow-tooltip': true
    },
    {
      label: '盖章方式',
      prop: 'suppliersSealType',
      formatter: row => {
        const [obj] = content.suppliersSealType.filter(item => item.dictId === row.suppliersSealType.toString())// 暂无字典 待修改字典名
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '签约状态',
      prop: 'status',
      formatter: row => {
        const [obj] = content.signStatusOptions.filter(item => item.dictId === row.status.toString())
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
