//在线签约 主页
<template>
  <div class="onlineSigning">
     <!-- 查询form -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    >
    </base-form>
    <!-- 表格 -->
     <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :dataSource.sync="tableData"
      :queryParas="queryParas"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :loadCount="loadCount"
      :webPage="false">
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <template slot="action" slot-scope="scope">
        <icon-button  @click="jumpSign(scope.row)" content="申请签约" v-if="scope.row.status==='0'" icon="iconfont iconshenqing"/>
        <icon-button  @click="jumpDetailes(scope.row)" content="查看" v-else  icon="iconfont iconchakan" />
      </template>
    </base-table>
  </div>
</template>
<script>
import { dataForm, dataTable } from './utils/config'
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseForm from '@/components/common/base-form//base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import Storage from '@/utils/storage'
import { signApi } from '@/api/businessApi'
import { getDictLists } from '@/filters/fromDict'

export default {
  name: 'onlineSigning',
  components: { baseForm, BaseTable, IconButton },
  data () {
    return {
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        category: 1
      },
      tableData: [],
      loadCount: 0

    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return signApi
    },
    // 签约状态
    signStatusOptions () {
      return (getDictLists('SIGN_TASK_STATUS')).filter(item => { // 过滤签约中
        if (item.dictId !== '1') {
          return item
        }
      })
    },
    // 盖章方式
    suppliersSealType () {
      return (getDictLists('SUPPLIERS_SEAL_TYPE')).filter(item => { // 过滤2.未确定类型
        if (item.dictId !== '2') {
          return item
        }
      })
    }
  },
  mounted () {
    this.handleFilter()
  },
  methods: {
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        category: 1
      }
    },
    // 跳转到申请签约
    jumpSign (row) {
      row.applyAmount = row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
      Storage.setSession('onlineSginData', row)
      if (row.suppliersSealType === '0') { // 鲜章申请
        this.$router.push({ path: '/business/applyOfflineSigning' })
      } else if (row.suppliersSealType === '1') { // 电子章申请
        this.$router.push({ path: '/business/applyOnlineSigning' })
      }
    },
    // 跳转到详情
    jumpDetailes (row) {
      row.applyAmount = row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
      Storage.setSession('onlineSginData', row)
      this.$router.push({ path: '/business/signingDetails' })
    }
  }
}
</script>
<style lang="scss" scoped />
